import "core-js/modules/es.array.push.js";
import { addAdmin, getMenu } from "@/api/api";
export default {
  name: "index",
  props: ['Row'],
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {
        id: '',
        menuIds: ''
      },
      menuList: [],
      props: {
        value: 'id',
        label: 'title',
        children: 'child',
        disabled: 'disabled'
      },
      checkTreeIds: [],
      defaultCheckedKeys: [],
      defaultExpandedKeys: []
    };
  },
  mounted() {
    this.getList1();
    let data = this.$root.useRoute.query.info;
    if (data) {
      this.Info = JSON.parse(data);
      console.log(this.Info);
      if (this.Info.menuIds) {
        let sid = this.Info.menuIds.split(","); //默认展开
        let intsid = sid.map(function (data) {
          return +data;
        });
        this.defaultExpandedKeys = sid;
        setTimeout(res => {
          this.selMenu(intsid);
        }, 300);
      }
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    getList1() {
      getMenu().then(res => {
        this.menuList = res.data;
        this.menuList.find((res, i) => {
          if (res.name == 'Index') {
            this.menuList[i].disabled = 'disabled';
          }
        });
      });
    },
    handleCheckChange(click, checked, index) {
      console.log(checked);
      let s1 = checked.checkedKeys.join(",");
      let s2 = checked.halfCheckedKeys.join(",");
      this.Info.menuIds = s1 + ',' + s2;
    },
    selMenu(so) {
      let IndexId = '';
      for (let i in this.menuList) {
        let l = this.menuList[i];
        console.log(so);
        if (this.menuList[i].name == 'Index') {
          IndexId = this.menuList[i].id;
        }
        if (so.includes(l.id)) {
          so.splice(so.indexOf(l.id), 1);
        }
      }
      if (IndexId) {
        so.push(IndexId);
      }
      this.defaultCheckedKeys = so;
    },
    subData() {
      //提交
      let method = 'put';
      addAdmin(this.Info, method).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.message
          });
          this.$root.useRouter.go(-1);
        } else {
          this.$root.ElMessage.error(res.message);
        }
      });
    }
  }
};